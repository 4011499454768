import { lazy } from 'react'

const DashboardHome = lazy(() => import('../../views/store/dashboard/home'))
const DashboardAHome = lazy(() => import('../../views/dashboard/analytics'))
const DashboardEHome = lazy(() => import('../../views/dashboard/ecommerce'))

const DashboardRoutes = [
  {
    path: '/dashboard/home',
    action: 'read',
    resource: 'dashboard',
    element: <DashboardHome />,
    meta: {
      action: 'read',
      resource: 'dashboard',
      subject: 'dashboard',
    }
  },
  {
    path: '/dashboard/analytics',
    action: 'read',
    resource: 'dashboard',
    element: <DashboardAHome />,
    meta: {
      action: 'read',
      resource: 'dashboard',
      subject: 'dashboard',
    }
  },
  {
    path: '/dashboard/ecommerce',
    action: 'read',
    resource: 'dashboard',
    element: <DashboardEHome />,
    meta: {
      action: 'read',
      resource: 'dashboard',
      subject: 'dashboard',
    }
  }
]

export default DashboardRoutes
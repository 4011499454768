// ** Icons Import
import { Home, Circle, Box, Activity, ShoppingCart } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboards',
    icon: <Home size={20} />,
    badge: 'light-warning',
    children: [
      {
        id: 'home',
        title: 'Home',
        icon: <Box size={12} />,
        navLink: '/dashboard/home',
        action: 'read',
        resource: 'dashboard'
      },
      {
        id: 'analyticsDash',
        title: 'Analytics',
        icon: <Activity />,
        navLink: '/dashboard/analytics'
      },
      {
        id: 'eCommerceDash',
        title: 'eCommerce',
        icon: <ShoppingCart />,
        navLink: '/dashboard/ecommerce'
      }
    ]
  }
]

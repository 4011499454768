// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

import pCategory from '@src/views/store/category/store'
import pCompany from '@src/views/store/company/store'
import pProject from '@src/views/store/project/store'
import pContact from '@src/views/store/contact/store'
import pProduct from '@src/views/store/product/store'
import pEnquiry from '@src/views/store/enquiry/store'
import pLead from '@src/views/store/lead/store'
import pAmc from '@src/views/store/amc/store'
import pSub from '@src/views/store/amctransactions/store'
import closedAMCStore from '@src/views/store/amctransactions/store/closedAMCStore'
import pUser from '@src/views/store/users/store'
import pTodo from '@src/views/store/todo/store'
import pTemplate from '@src/views/store/template/store'
import pMilestone from '@src/views/store/milestone/store'
import pAnnouncement from '@src/views/store/announcement/store'
import pPermissions from '@src/views/store/permission/store'
import masterState from './masterStore'

//new
import pStudent from '@src/views/store/student/store'
import pActivity from '@src/views/store/activity/store'
import pAStudent from '@src/views/store/approvalStudent/store'
import pReporting from '@src/views/store/reporting/store'
import pLocation from '@src/views/store/location/store'
import pClass from '@src/views/store/class/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,

  pCategory,
  pCompany,
  masterState,
  pTemplate,
  pPermissions,
  pContact,
  pProduct,
  pEnquiry,
  pLead,
  pAmc,
  pSub,
  pUser,
  pAnnouncement,
  pTodo,
  pProject,
  pMilestone,
  closedAMCStore,
  // new
  pStudent,
  pLocation,
  pClass,
  pAStudent,
  pReporting,
  pActivity,
}

export default rootReducer

import { lazy } from 'react'
import { BsCardChecklist } from 'react-icons/bs'

// const UsersPage = lazy(() => import('../../views/store/user/list'))
// const CategoryPage = lazy(() => import('../../views/store/category/list'))
// const CategoryFormUpdate = lazy(() => import('../../views/store/category/view/CategoryFormUpdate'))

const AnnouncementTPage = lazy(() => import('../../views/store/announcement/list'))
const AnnouncementFormPage = lazy(() => import('../../views/store/announcement/view/FormUpdate.js'))

const User = lazy(() => import('../../views/store/users/list'))
const UserFormPage = lazy(() => import('../../views/store/users/view/FormUpdate.js'))
const UserDetailPage = lazy(() => import('../../views/store/users/details/index.js'))
const Profile = lazy(() => import('../../views/store/users/profile/index.js'))
const Permission = lazy(() => import('../../views/store/permission/index.js'))

const DbDownload = lazy(()=> import('../../views/store/db-download'))


 // new 

const StudentPage = lazy(() => import('../../views/store/student/list'))
const StudentEditPage = lazy(() => import('../../views/store/student/view/FormUpdate.js'))
const StudentDetailPage = lazy(() => import('../../views/store/student/details/index.js'))
const ApprovalStudentPage = lazy(() => import('../../views/store/approvalStudent/list'))
const AttendanceReporting = lazy(() => import('../../views/store/reporting/attendance'))

const LocationPage = lazy(() => import('../../views/store/location/list'))
const LocationEditPage = lazy(() => import('../../views/store/location/view/FormUpdate.js'))
const LocationDetailPage = lazy(() => import('../../views/store/location/details/index.js'))

const ClassPage = lazy(() => import('../../views/store/class/list'))
const ClassEditPage = lazy(() => import('../../views/store/class/view/FormUpdate.js'))
const ClassDetailPage = lazy(() => import('../../views/store/class/details/index.js'))

const Attendance = lazy(()=> import('../../views/store/attendance'))
const TechAttendance = lazy(()=> import('../../views/store/techattendance'))


const ActivityPage = lazy(() => import('../../views/store/activity/list'))
const ActivityEditPage = lazy(() => import('../../views/store/activity/view/FormUpdate.js'))
const ActivityDetailPage = lazy(() => import('../../views/store/activity/details/index.js'))


const DashboardRoutes = [
  // Contacts/Vendor Routes
  {
    path: '/p/users/list',
    element: <User />,
    meta: {
      action: 'read',
      resource: 'users'
    }
  },
  {
    path: '/p/users/add',
    element: <UserFormPage />,
    meta: {
      action: 'add',
      resource: 'users'
    }
  },
  {
    path: '/p/users/:id/edit',
    element: <UserFormPage />,
    meta: {
      action: 'update',
      resource: 'users'
    }
  },
  {
    path: '/p/users/roles',
    element: <Permission />,
    meta: {
      action: 'read',
      resource: 'roles'
    }
  },
  {
    path: '/p/users/view/:id',
    element: <UserDetailPage />,
    meta: {
      action: 'detail',
      resource: 'users'
    }
  },
  {
    path: '/p/users/profile',
    element: <Profile />
  },
  
  // announcement
  {
    path: '/p/announcement/list',
    element: <AnnouncementTPage />,
    meta: {
      action: 'read',
      resource: 'announcement'
    }
  },
  {
    path: '/p/announcement/add',
    element: <AnnouncementFormPage />,
    meta: {
      action: 'add',
      resource: 'announcement'
    }
  },
  {
    path: '/p/announcement/:id/edit',
    element: <AnnouncementFormPage />,
    meta: {
      action: 'update',
      resource: 'announcement'
    }
  },
  {
    element: <DbDownload />,
    path: '/p/db',
    meta: {
      appLayout: true,
      className: 'db-add',
      action: 'add',
      resource: 'db'
    }
  },
  // new 
  {
    path: '/p/student/list',
    element: <StudentPage />,
    meta: {
      action: 'read',
      resource: 'student'
    }
  },
  {
    path: '/p/student/approval-list',
    element: <ApprovalStudentPage />,
    meta: {
      action: 'update',
      resource: 'studentapproval'
    }
  },
  {
    path: '/p/student/add',
    element: <StudentEditPage />,
    meta: {
      action: 'add',
      resource: 'student'
    }
  },
  {
    path: '/p/student/:id/edit',
    element: <StudentEditPage />,
    meta: {
      action: 'update',
      resource: 'student'
    }
  },
  {
    path: '/p/student/view/:id',
    element: <StudentDetailPage />,
    meta: {
      action: 'detail',
      resource: 'student'
    }
  },

  // activity
  {
    path: '/p/activity/list',
    element: <ActivityPage />,
    meta: {
      action: 'read',
      resource: 'activity'
    }
  },
  {
    path: '/p/activity/add',
    element: <ActivityEditPage />,
    meta: {
      action: 'add',
      resource: 'activity'
    }
  },
  {
    path: '/p/activity/:id/edit',
    element: <ActivityEditPage />,
    meta: {
      action: 'update',
      resource: 'activity'
    }
  },
  {
    path: '/p/activity/view/:id',
    element: <ActivityDetailPage />,
    meta: {
      action: 'detail',
      resource: 'activity'
    }
  },

  // location
  {
    path: '/p/location/list',
    element: <LocationPage />,
    meta: {
      action: 'read',
      resource: 'location'
    }
  },
  {
    path: '/p/location/add',
    element: <LocationEditPage />,
    meta: {
      action: 'add',
      resource: 'location'
    }
  },
  {
    path: '/p/location/:id/edit',
    element: <LocationEditPage />,
    meta: {
      action: 'update',
      resource: 'location'
    }
  },
  {
    path: '/p/location/view/:id',
    element: <LocationDetailPage />,
    meta: {
      action: 'detail',
      resource: 'location'
    }
  },
  
  {
    path: '/p/class/list',
    element: <ClassPage />,
    meta: {
      action: 'read',
      resource: 'class'
    }
  },
  {
    path: '/p/class/add',
    element: <ClassEditPage />,
    meta: {
      action: 'add',
      resource: 'class'
    }
  },
  {
    path: '/p/class/:id/edit',
    element: <ClassEditPage />,
    meta: {
      action: 'update',
      resource: 'class'
    }
  },
  {
    path: '/p/class/view/:id',
    element: <ClassDetailPage />,
    meta: {
      action: 'detail',
      resource: 'class'
    }
  },
  {
    path: '/p/reporting/attendance-students',
    element: <AttendanceReporting />,
    meta: {
      action: 'read',
      resource: 'reporting_attendance'
    }
  },
  {
    path: '/p/reporting/attendance-teachers',
    element: <AttendanceReporting />,
    meta: {
      action: 'list',
      resource: 'reporting_attendance'
    }
  },
  {
    element: <Attendance />,
    path: '/p/attendance',
    meta: {
      appLayout: true,
      className: 'attendance-management',
      action: 'add',
      resource: 'stu'
    }
  },
  {
    element: <TechAttendance />,
    path: '/p/attendance-teachers',
    meta: {
      appLayout: true,
      className: 'attendance-management',
      action: 'add',
      resource: 'tech'
    }
  },
]

export default DashboardRoutes
/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getStudentAttendanceData = createAsyncThunk(
  'appReporting/getStudentAttendanceData',
  async (params) => {
    const response = await axios.get(`/s-reporting-attendance`, {
      params
    })
    return {
      params,
      data: response.data.data,
      pagination: response.data.pagination
    }
  }
)

export const getStudent = createAsyncThunk(
  'appReporting/getStudent',
  async (id) => {
    const response = await axios.get(`/students/show/${id}`)
    return response.data.data
  }
)

export const approveStudent = createAsyncThunk(
  'appReporting/approveStudent',
  async ({ id, data, navigator }, { dispatch, getState }) => {
    try {
      await axios.post(`students/student-approve/${id}`, data)
      await dispatch(getData(getState().pReporting.params))
      navigator ? navigator('/p/student/approval-list') : ''
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const appReportingSlice = createSlice({
  name: 'appReporting',
  initialState: {
    data: [],
    reportingData: {
      sAttendance:{
        raw: [],
        totalAbsent:[],
        totalPresent:[],
        groupData:[]
      }
    },
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentAttendanceData.fulfilled, (state, action) => {
        state.reportingData.sAttendance.raw = action.payload.data
        state.reportingData.sAttendance.totalPresent = state.reportingData.sAttendance.raw.filter(s => s.aStatus=='p')
        state.reportingData.sAttendance.totalAbsent = state.reportingData.sAttendance.raw.filter(s => s.aStatus=='a')
        let dateObj = {}
        state.reportingData.sAttendance.raw.forEach(s => {
          if(!dateObj[s.formattedDate]) dateObj[s.formattedDate] = {}
          if(!dateObj[s.formattedDate]['a']) dateObj[s.formattedDate]['a'] = []
          if(!dateObj[s.formattedDate]['p']) dateObj[s.formattedDate]['p'] = []
          dateObj[s.formattedDate][s.aStatus].push(s)
        })
        state.reportingData.sAttendance.groupData = dateObj
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } =
  appReportingSlice.actions

export default appReportingSlice.reducer

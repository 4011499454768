import {
  Users,
  Check,
  Plus,
  List,
  Command,
  Mic,
  Database,
  Activity
} from 'react-feather'
import { AiOutlineIdcard } from 'react-icons/ai'
import { TfiLocationPin } from 'react-icons/tfi'
import { BsPeople, BsCardChecklist, BsAsterisk } from 'react-icons/bs'
import { LiaRepublicanSolid } from 'react-icons/lia'

export default [
  {
    header: 'Masters',
    action: 'read',
    resource: 'header'
  },
  {
    id: '_opr_location',
    title: 'Location (GS)',
    icon: <TfiLocationPin size={20} />,
    children: [
      {
        id: '_opr_location_list',
        title: 'List',
        icon: <List size={12} />,
        navLink: '/p/location/list',
        action: 'read',
        resource: 'location'
      },
      {
        id: '_opr_location_add',
        title: 'Add New',
        icon: <Plus size={12} />,
        navLink: '/p/location/add',
        action: 'add',
        resource: 'location'
      }
    ]
  },

  {
    id: '_opr_student',
    title: 'Students',
    icon: <AiOutlineIdcard size={20} />,
    children: [
      {
        id: '_opr_student_list',
        title: 'List',
        icon: <List size={12} />,
        navLink: '/p/student/list',
        action: 'read',
        resource: 'student'
      },
      {
        id: '_opr_student_add',
        title: 'Add New',
        icon: <Plus size={12} />,
        navLink: '/p/student/add',
        action: 'add',
        resource: 'student'
      },
      {
        id: '_opr_student_approval_list',
        title: 'Approval List',
        icon: <Check size={12} />,
        navLink: '/p/student/approval-list',
        action: 'update',
        resource: 'studentapproval'
      },
      {
        id: '_opr_student_add_public',
        title: 'Registration Form',
        icon: <LiaRepublicanSolid size={12} />,
        navLink: '/public/form/register',
        action: 'read',
        resource: 'student',
        newTab: true,
        navLink: '/public/form/register'
      },
      {
        id: '_opr_activity_list',
        title: 'Activities',
        icon: <Activity size={12} />,
        navLink: '/p/activity/list',
        action: 'list',
        resource: 'activity'
      }
    ]
  },

  {
    id: '_opr_class',
    title: 'Classes',
    icon: <BsPeople size={20} />,
    children: [
      {
        id: '_opr_class_list',
        title: 'List',
        icon: <List size={12} />,
        navLink: '/p/class/list',
        action: 'read',
        resource: 'class'
      },
      {
        id: '_opr_class_add',
        title: 'Add New',
        icon: <Plus size={12} />,
        navLink: '/p/class/add',
        action: 'add',
        resource: 'class'
      }
    ]
  },

  {
    header: 'Operations',
    action: 'read',
    resource: 'header'
  },
  {
    id: 'stu-add',
    action: 'add',
    resource: 'stu',
    title: 'Attendance Stdnt',
    icon: <BsCardChecklist size={12} />,
    navLink: '/p/attendance'
  },
  {
    id: 'tech-add',
    action: 'add',
    resource: 'tech',
    title: 'Attendance Tchr',
    icon: <BsCardChecklist size={12} />,
    navLink: '/p/attendance-teachers'
  },
  {
    id: '_opr_announcement',
    title: 'Announcements',
    icon: <Mic size={20} />,
    children: [
      {
        id: '_opr_announcement_list',
        title: 'List',
        icon: <List size={12} />,
        navLink: '/p/announcement/list',
        action: 'read',
        resource: 'announcement'
      },
      {
        id: '_opr_announcement_add',
        title: 'Add',
        icon: <Plus size={12} />,
        navLink: '/p/announcement/add',
        action: 'add',
        resource: 'announcement'
      }
    ]
  },
  {
    header: 'User Management',
    action: 'read',
    resource: 'header'
  },
  {
    id: '_scom_users',
    title: 'Users',
    icon: <Users size={20} />,
    children: [
      {
        id: '_scom_users_list',
        title: 'List',
        icon: <List size={12} />,
        navLink: '/p/users/list',
        action: 'read',
        resource: 'users'
      },
      {
        id: '_scom_users_add',
        title: 'Add',
        icon: <Plus size={12} />,
        navLink: '/p/users/add',
        action: 'add',
        resource: 'users'
      },
      {
        id: '_scom_roles_list',
        title: 'Roles & Permissions',
        icon: <Command size={12} />,
        navLink: '/p/users/roles',
        action: 'read',
        resource: 'roles'
      }
    ]
  },
  {
    header: 'Reporting',
    action: 'read',
    resource: 'reporting_attendance'
  },
  {
    id: '_scom_reporting',
    title: 'Reporting',
    icon: <BsAsterisk size={20} />,
    children: [
      {
        id: '_scom_reporting_students_list',
        title: 'S. Attendance',
        icon: <BsCardChecklist size={12} />,
        navLink: '/p/reporting/attendance-students',
        action: 'read',
        resource: 'reporting_attendance'
      },
      {
        id: '_scom_reporting_teachers_list',
        title: 'T. Attendance',
        icon: <BsCardChecklist size={12} />,
        navLink: '/p/reporting/attendance-teachers',
        action: 'read',
        resource: 'reporting_attendance'
      }
    ]
  },
  {
    id: 'db-download',
    action: 'add',
    resource: 'db',
    title: 'DB Management',
    icon: <Database size={12} />,
    navLink: '/p/db'
  }
]

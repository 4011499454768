import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@src/@core/apiFace/index'
import {
  amcStage,
  businessNature,
  contactTags,
  countryNames,
  enquiryStages,
  gsRole,
  milestoneStages,
  projectStages,
  studentTags,
  taskStatusOptions,
  templateTypes,
  gyanShalaListPublic,
  gyanShalaClasses
} from './rawData'

export const getCountry = createAsyncThunk(
  'master/getCountry',
  async (undefined, { getState }) => {
    if (!getState().masterState.country.length) {
      const response = await axios.get(`/countries`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getCity = createAsyncThunk(
  'master/getCity',
  async (undefined, { getState }) => {
    if (!getState().masterState.city.length) {
      const response = await axios.get(`/cities`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getState = createAsyncThunk(
  'master/getState',
  async (undefined, { getState }) => {
    if (!getState().masterState.state.length) {
      const response = await axios.get(`/states`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getLocations = createAsyncThunk(
  'master/getLocations',
  async (additionalData, { getState }) => {
    if (!getState().masterState.location.length) {
      const response = await axios.get(`/alllocations`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getClasses = createAsyncThunk(
  'master/getClasses',
  async (additionalData, { getState }) => {
    if (!getState().masterState.classes.length) {
      const response = await axios.get(`/allclasses`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getActivities = createAsyncThunk(
  'master/getActivities',
  async (additionalData, { getState }) => {
    if (!getState().masterState.activities.length) {
      const response = await axios.get(`/allactivities`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getStudents = createAsyncThunk(
  'master/getStudents',
  async (additionalData, { getState }) => {
    if (!getState().masterState.students.length) {
      const response = await axios.get(`/allstudents`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getAllTemplates = createAsyncThunk(
  'master/getTemplates',
  async (undefined, { getState }) => {
    if (!getState().masterState.template.length) {
      const response = await axios.get(`/alltemplates`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getDesignation = createAsyncThunk(
  'master/getDesignation',
  async (undefined, { getState }) => {
    if (!getState().masterState.designation.length) {
      const response = await axios.get(`/designation`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getAllCompany = createAsyncThunk(
  'master/getAllCompany',
  async (undefined, { getState }) => {
    if (!getState().masterState.companyWith.length) {
      const response = await axios.get(`/companylist`)
      return response.data.data
    } else {
      return null
    }
  }
)
export const getAllProducts = createAsyncThunk(
  'master/getAllProducts',
  async (undefined, { getState }) => {
    if (!getState().masterState.products.length) {
      const response = await axios.get(`/productlist`)
      return response.data.data
    } else {
      return null
    }
  }
)
export const getAllContacts = createAsyncThunk(
  'master/getAllContacts',
  async (undefined, { getState }) => {
    if (!getState().masterState.refferedBy.length) {
      const response = await axios.get(`/contactlist`)
      return response.data.data
    } else {
      return null
    }
  }
)
export const getAllUsers = createAsyncThunk(
  'master/getAllUsers',
  async (additionalData, { getState }) => {
    if (!getState().masterState.users.length) {
      const response = await axios.get(`/userlist`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getRUsers = createAsyncThunk(
  'master/getRUsers',
  async (undefined, { getState }) => {
    if (!getState().masterState.rusers.length) {
      const response = await axios.get(`/rusers`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const getAllContactsNumbers = createAsyncThunk(
  'master/getAllContactsNumbers',
  async (undefined, { getState }) => {
    if (!getState().masterState.allContacts.length) {
      const response = await axios.get(`/allcontactlist`)
      return response.data.data
    } else {
      return null
    }
  }
)

export const masterState = createSlice({
  name: 'masterState',
  initialState: {
    countryName: countryNames,
    city: [],
    state: [],
    businessNature: businessNature,
    city: [],
    state: [],
    template: [],
    country: [],
    designation: [],
    companyWith: [],
    refferedBy: [],
    stage: enquiryStages,
    projectStages: projectStages,
    milestoneStages: milestoneStages,
    products: [],
    assignedTo: [],
    contacts: [],
    amcStage: amcStage,
    rusers: [],
    taskStatus: taskStatusOptions,
    contactTags: contactTags,
    type: templateTypes,
    allContacts: [],
    // new
    location: [],
    secondaryEdu: [],
    students: [],
    activities: [],
    users: [],
    classes: [],
    studentTags: studentTags,
    gsRole,
    public:{
      gyanShalaListPublic,
      gyanShalaClasses
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountry.fulfilled, (state, action) => {
        if (action.payload) {
          state.country = action.payload.map((e) => {
            return { value: e, label: e }
          })
        }
      })
      .addCase(getCity.fulfilled, (state, action) => {
        if (action.payload) {
          state.city = action.payload.map((e) => {
            return { value: e, label: e }
          })
        }
      })
      .addCase(getState.fulfilled, (state, action) => {
        if (action.payload) {
          state.state = action.payload.map((e) => {
            return { value: e, label: e }
          })
        }
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        if (action.payload) {
          state.location = action.payload.map((e) => {
            return { value: e._id, label: `${e.name} | ${e.city}`, raw: e }
          })
        }
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        if (action.payload) {
          state.students = action.payload.map((e) => {
            return {
              value: e._id,
              label: `${e.name} | ${e.fatherName}/${e.motherName}`,
              raw: e
            }
          })
        }
      })
      .addCase(getClasses.fulfilled, (state, action) => {
        if (action.payload) {
          state.classes = action.payload.map((e) => {
            return { value: e._id, label: `${e.name}`, raw: e }
          })
          state.secondaryEdu = state.classes
        }
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        if (action.payload) {
          state.activities = action.payload.map((e) => {
            return { value: e.name, label: `${e.name}`}
          })
        }
      })
      .addCase(getDesignation.fulfilled, (state, action) => {
        if (action.payload) {
          state.designation = action.payload.map((e) => {
            return { value: e, label: e }
          })
        }
      })
      .addCase(getAllCompany.fulfilled, (state, action) => {
        if (action.payload) {
          state.companyWith = action.payload.map((e) => {
            return {
              value: e._id,
              label: (
                <div>
                  <p className="h6 m-25">{e.name}</p>
                  <p className="m-25 hide-single">
                    <small>{e.aliasName}</small>
                  </p>
                </div>
              ),
              search: `${e.name}-${e.aliasName}`
            }
          })
        }
      })
      .addCase(getAllContacts.fulfilled, (state, action) => {
        if (action.payload) {
          state.refferedBy = action.payload.map((e) => {
            return {
              value: e._id,
              label: (
                <div>
                  <p className="h6 m-25">{e.name}</p>
                  <p className="m-25 hide-single">
                    <small>{e.aliasName}</small>
                  </p>
                </div>
              ),
              search: `${e.name}-${e.aliasName}`
            }
          })
          state.contacts = state.refferedBy
        }
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        if (action.payload) {
          state.products = action.payload.map((e) => {
            return {
              value: e._id,
              label: (
                <div>
                  <p className="h6 m-25">{e.name}</p>
                </div>
              ),
              search: `${e.name}`
            }
          })
        }
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        if (action.payload) {
          state.users = action.payload.map((e) => {
            return {
              value: e._id,
              label: (
                <div>
                  <p className="h6 m-25">{e.name}</p>
                </div>
              ),
              search: `${e.name}`,
              raw: e
            }
          })
          state.rusers = action.payload || []
        }
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        if (action.payload) {
          state.template = action.payload.map((e) => {
            return {
              value: e._id,
              label: (
                <div>
                  <p className="h6 m-25">
                    {e.name} - {e.type}
                  </p>
                </div>
              ),
              search: `${e.name} ${e.type}`
            }
          })
        }
      })
      .addCase(getAllContactsNumbers.fulfilled, (state, action) => {
        if (action.payload) {
          state.allContacts = action.payload.map((e) => {
            return {
              value: e.number,
              label: (
                <div>
                  <p className="h6">
                    {e.name} | {e.number}
                  </p>
                </div>
              ),
              search: `${e.name} ${e.number}`,
              _id: e._id
            }
          })
        }
      })
  }
})

export default masterState.reducer
